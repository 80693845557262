import React, {useState} from 'react';
import styles from './../../css/MainPage.module.css';
import {Link, useNavigate} from 'react-router-dom';
import HeroDronInsurance from './../../img/DronInsuranceHero.png'
import HeroRegistration from './../../img/HeroRegistration.png'
import HeroPilot from './../../img/HeroPilot.png'
import HeroCategories from './../../img/HeroCategories.png'
import HeroTraining from './../../img/HeroTraining.png'
import NavBar from "../Navbar/NavBar";
import InsuranceCardList from "./InsuranceCardList";
import AboutUs from "./AboutUs";
import GoogleReviews from "./GoogleReviews";
import Footer from "../Footer";
import MoreIcon from "./../../img/MoreIcon.png"
import CalculationIcon from "./../../img/Diagonal  Right Up Arrow.png"

function MainPage() {
    const slides = [
        {
            title: 'Osiguranje drona',
            description: 'Ugovorite online policu obveznog osiguranja za dron.\n' +
                'Dron osiguranje na dohvat ruke, kasko ili osiguranje od odgovornosti operatora bespilotnog zrakoplova.',
            image: HeroDronInsurance,
            learnMoreLink: '/dronInsurance',
        },
        {
            title: 'Registracija operatora',
            description: 'Osiguranje drona, registracija operatora, osposobljavanje udaljenog pilota i prijava leta procedura je koju je potrebno odraditi za legalno letenje.',
            image: HeroRegistration,
            learnMoreLink: '/registration',
        },
        {
            title: 'Osposobljavanje pilota',
            description: 'Polaganje ispita za udaljene pilote provodi se neovisno o registraciji operatora (pilot ne mora posjedovati dron) i obvezan je za sve koji misle upravljati s dronom čiji je MTOM veći od 250 g.',
            image: HeroPilot,
            learnMoreLink: '/training',
        },
        {
            title: 'Kategorije letačkih operacija',
            description: 'Otvorena, posebna i certificirana kategorija',
            image: HeroCategories,
            learnMoreLink: '/categories',
        },
        {
            title: 'Zakonska regulativa',
            description: 'Koje radnje je potrebno poduzeti prije leta. Operator ili udaljeni pilot? Uredbe i propisi.',
            image: HeroTraining,
            learnMoreLink: '/law',
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const navigate = useNavigate();

    const handleButtonClick = (index) => {
        setCurrentSlide(index);
    };

    const handleLearnMoreClick = () => {
        navigate(slides[currentSlide].learnMoreLink);
    };

    return (
        <div className={styles.mainContainer}>
            <NavBar/>
            <div className="elfsight-app-c31a1fd4-587c-4f10-8d28-5c7c024df230" data-elfsight-app-lazy></div>
            <div className={styles.pageContainer}>
                <div className={styles.leftColumn}>
                    <h1>{slides[currentSlide].title}</h1>
                    <p>{slides[currentSlide].description}</p>

                    {/* Dva nova gumba ispod opisa */}
                    <div className={styles.buttonRow}>
                        <Link to={"/form"}>
                            <button className={styles.calcButton}>Izračun <img src={CalculationIcon} alt=""/></button>
                        </Link>
                        <button className={styles.learnMoreButton} onClick={handleLearnMoreClick}>
                            Saznaj više <img src={MoreIcon} alt=""/>
                        </button>
                    </div>

                    {/* Gumbi sa slikama */}
                    <div className={styles.buttonContainer}>
                        {slides.map((slide, index) => (
                            <button
                                key={index}
                                className={styles.button}
                                onClick={() => handleButtonClick(index)}
                            >
                                <img src={slide.image} alt={`Button image ${index + 1}`}/>
                            </button>
                        ))}
                    </div>
                </div>

                {/* Desna kolona s velikom slikom */}
                <div className={styles.rightColumn}>
                    <img
                        src={slides[currentSlide].image}
                        alt={`Slide image ${currentSlide + 1}`}
                        className={styles.mainImage}
                    />
                </div>
            </div>
            <InsuranceCardList/>
            <AboutUs/>
            <Footer/>


        </div>
    );
}

export default MainPage;
