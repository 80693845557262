import React from 'react';
import styles from './../../css/AboutSection.module.css';
import ContentBlock from './ContentBlock';

const contentBlocks = [
  {
    title: "Osposobljavanje udaljenog pilota",
    content: "Polaganje ispita za udaljene pilote provodi se neovisno o registraciji operatora (pilot ne mora posjedovati dron) i obvezan je za sve koji će izvoditi letačke operacije s dronom čiji je MTOM veći od 250 g."
  },
  {
    title: "Tko polaže ispit",
    content: "Provedbena Uredba Komisije (EU) 2019/947 od 24. svibnja 2019. o pravilima i postupcima za rad bespilotnih zrakoplova,  propisuje da će udaljeni piloti prije izvođenja operacija bespilotnim zrakoplovima u „otvorenoj kategoriji“ morati položiti teorijske ispite. Otvorena kategorija UAS operacija podijeljena je na tri potkategorije: A1, A2 i A3, na temelju radnih ograničenja, zahtjeva koji se odnose na udaljene pilote i tehničkih zahtjeva za UAS-e."
  },
  {
    title: "U praksi",
    content: "Polaganje ispita za udaljenog pilota potkategorije A1/A3 omogućeno je na web stranicama HACZ (CCAA), dok se polaganje za potkategoriju A2 provodi prema rasporedu, objavljenom na web stranicama HACZ, na nekoliko lokacija u Republici Hrvatskoj. Uredba ne propisuje obvezu polaganja ispita za dronove čiji je MTOM manji od 250 g, ali je to preporučljivo radi upoznavanja s pravilima letenja. Polaganju ispita prethodi upoznavanje s raznim segmentima koji utječu na sigurno izvođenje letačkih operacija, kao što su: Sigurnost zračnog prometa, Ograničenja u vezi sa zračnim prostorom, Zrakoplovni propisi, Ljudska ograničenja, Radni postupci, Opće znanje o bespilotnim zrakoplovima, Privatnost i zaštita podataka, Police osiguranja drona i Zaštita."
  },
  {
    title: "",
    content: "Detaljne informacije o osposobljavanju udaljenih pilota pronađite u dokumentu UAS-UPUTE-005 na sljedećoj stranici: https://www.ccaa.hr/obrasci-i-publikacije-42179"
  },

];

function AboutSection() {
  return (
    <section className={styles.aboutSection}>
      <div className={styles.contentWrapper}>
        {contentBlocks.map((block, index) => (
          <ContentBlock key={index} title={block.title} content={block.content} />
        ))}
      </div>
    </section>
  );
}

export default AboutSection;