import React from "react";
import styles from "./../../css/ContactUs.module.css";

function ContactUs() {
  return (
    <section className={styles.ctaSection}>
      <div className={styles.ctaContent}>
        <h2 className={styles.ctaTitle}>Stojimo Vam na usluzi!</h2>
        <p className={styles.ctaDescription}>
         Za sve upite i nejasnoće oko osiguranja drona, registracije operatora i osposobljavanja udaljenih pilota slobodno nam se javite.
        </p>
        <button className={styles.ctaButton}>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d37cb58753dea06d864b31eb103be00acc03c00c7672da436b868fe649df14a?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" alt="" className={styles.ctaButtonIcon} />
          <span>Kontaktirajte nas</span>
        </button>
      </div>
    </section>
  );
}

export default ContactUs;