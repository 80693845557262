import React from 'react';
import InsuranceCard from './InsuranceCard';
import styles from './../../css/InsuranceCard.module.css';

const insuranceData = [
  {
    title: "OBVEZNO OSIGURANJE OD ODGOVORNOSTI OPERATERA BESPILOTNOG ZRAKOPLOVA",
    description: "Osiguranje drona, registracija operatora, osposobljavanje udaljenog pilota i prijava leta je procedura koju je potrebno...",
    link: "/insurance" // Dodaj link
  },
  {
    title: "KASKO OSIGURANJE BESPILOTNOG ZRAKOPLOVA",
    description: "Kasko osiguranjem pokrivene su štete nastale oštećenjem, uništenjem ili nestankom bespilotnog zrakoplova od sljedećih rizika...",
    link: "/kasko" // Dodaj link
  },
  {
    title: "OSTALE VRSTE OSIGURANJA",
    description: "Cari properti yang diinginkan sesuai dengan kriteria anda dan keluarga. Cara memilih properti yang bijak dapat anda baca lebih lanjut di sini",
    link: "/otherInsurances" // Dodaj link
  }
];

const InsuranceCardList = () => {
  return (
    <section className={styles.cardContainer}>
      {insuranceData.map((insurance, index) => (
        <InsuranceCard
          key={index}
          title={insurance.title}
          description={insurance.description}
          link={insurance.link} // Prosledi link kao prop
        />
      ))}
    </section>
  );
};

export default InsuranceCardList;
