import React from 'react';
import styles from './../../css/Registration.module.css';
import NavBar from './../Navbar/NavBar';
import ServiceSteps from './ServiceSteps';
import CallToAction from './CallToAction';
import Footer from './../Footer';

function Registration() {
  return (
    <div className={styles.registration}>
      <NavBar />
      <ServiceSteps />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default Registration;