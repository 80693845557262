import React from 'react';
import Header from './Header';
import FeaturedPost from './FeaturedPost';
import PostGrid from './PostGrid';
import styles from './../../css/NewsPage.module.css';
import Footer from "../Footer";
import NavBar from "../Navbar/NavBar";

const NewsPage = () => {
    return (

        <div>
            <NavBar/>
            <div className={styles.newsPage}>

                <main className={styles.mainContent}>
                    <Header/>
                    <FeaturedPost/>
                    <PostGrid/>
                </main>
                <Footer/>
            </div>
        </div>


    );
};

export default NewsPage;