import * as React from 'react';
import logo from './../../img/LOGO_pozitiv_transparentback.33169ec057907e68abb3 1.png';
import document from './../../document/Upitnik za osiguranje bespilotnog zrakoplova.docx'
import {Link} from "react-router-dom";

function NavBar() {
  return (
    <nav className="navbar navbar-expand-lg bg-light" style={{zIndex:"10" }}>
      <div className="container-fluid" style={{ maxWidth: "1152px" }}>
        <a className="navbar-brand" href="/">
          <img src={logo} alt="Logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0 text-center">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Osiguranja
              </a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/insurance">Obavezno osiguranje</a></li>
                <li><a className="dropdown-item" href="/kasko">Kasko osiguranje</a></li>
                <li><a className="dropdown-item" href="/dronInsurance">Osiguranje drona</a></li>
                <li><a className="dropdown-item" href="/registration">Registracija operatora</a></li>
                <li><hr className="dropdown-divider" /></li>
                <li><a className="dropdown-item" href="/law">Zakonska regulativa</a></li>
                <li><a className="dropdown-item" href="/categories">Kategorije letačkih operacija</a></li>
                <li><a className="dropdown-item" href="/training">Osposobljavanje udaljenog pilota</a></li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/faq">FAQ</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/history">Povijest</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/blog">Aktualnosti</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={document} target={"_blank"}>Upitnik</a>
            </li>
          </ul>
          <form className="d-flex" role="search">

            <a href="/eng" style={{textDecoration:"none"}}>
              <div className="btn">EN</div>
            </a>
            <a href="/contact" style={{textDecoration:"none"}}>
              <button
                  className="btn"
                  type="submit"
                  style={{backgroundColor: "#2f4156", color: "white"}} // Tamno plava boja
              >
                Kontaktirajte nas
              </button>
            </a>
          </form>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
