import React from 'react';
import styles from './../../css/NewsPage.module.css';

const PostCard = ({ image, title, date }) => {
  return (
    <article className={styles.postCard}>
      <img src={image} alt="" className={styles.image} />
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.date}>{date}</p>
      </div>
    </article>
  );
};

export default PostCard;