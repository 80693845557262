import React from 'react';
import styles from './../../css/AboutSection.module.css';
import ContentBlock from './ContentBlock';

const contentBlocks = [
  {
    title: "Drone Insurance Croatia",
    content: "A Drone Operator's Liability Insurance Policy (a certificate of third-party liability insurance) is mandatory under EASA regulations for drones weighing over 20 kg, as well as for all drones performing flight operations, such as the DJI Mini 4 Pro, DJI Air 3, DJI Mavic 3. Flight operations are considered aerial work activities, such as filming, photography, advertising, surveillance, and similar tasks, whether for compensation or not, and for private or public purposes."
  },
  {
    title: "",
    content: "Natural and legal persons from third countries (foreign citizens) can purchase an insurance policy for private purposes (sports and hobbies) or commercial purposes (filming, photography, advertising, surveillance, scientific flights, research flights, protection and rescue, agriculture, industry, etc.)."
  },
  {
    title: "",
    content: "The insured amount is in accordance with Regulation (EC) 785/2004 and is set at 750,000 SDR (Special Drawing Rights)."
  },
  {
    title: "",
    content: "The insurance policy covers the operator’s liability for damages to third parties, including death, bodily injury, health damage, destruction, or damage to property."
  },
    {
    title: "",
    content: "Mandatory drone liability insurance and drone insurance can be arranged by filling out the QUESTIONNAIRE and sending it to the email address hrvoje.sremic@sps.hr."
  },
  {
    title: "",
    content: "Hull insurance requires third-party liability insurance. To apply for hull insurance, please send an invoice, a few pictures of the drone and equipment, ensuring that the drone's serial number is visible in at least one picture."
  },
  {
    title: "",
    content: "This year (23.07.2024), we are celebrating 10 years since the first drone liability insurance policy was issued. Since then, we have been actively collaborating with the CCAA and insurance companies to develop both liability and hull insurance for drones. Over time, we have expanded the territorial coverage for liability insurance, created the first hull insurance policy, and the latest development was the introduction of insurance premiums based on drone categories in accordance with European Commission Regulations 2019/945 and 2019/947."
  },
  {
    title: "",
    content: "Our 10 years of experience in drone insurance have earned the trust of many private companies across various industries: professional photographers, surveyors, film productions, government institutions, cities, universities, research institutes, and private users."
  },
  {
    title: "",
    content: "Most general liability insurance policies exclude coverage for unmanned aircraft or drones, including them under broader aircraft liability exclusions. Since liability insurance protects your business in the event of legal, medical, or privacy claims and lawsuits, having a separate drone liability insurance policy is a must."
  }
];

function AboutSection() {
  return (
    <section className={styles.aboutSection}>
      <div className={styles.contentWrapper}>
        {contentBlocks.map((block, index) => (
          <ContentBlock key={index} title={block.title} content={block.content} />
        ))}
      </div>
    </section>
  );
}

export default AboutSection;