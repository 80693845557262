import React from 'react';
import styles from './../../css/AboutSection.module.css';
import ContentBlock from './ContentBlock';

const contentBlocks = [
  {
    title: "Kategorije letačkih operacija",
    content: "Otvorena, posebna i certificirana kategorija. Koji dron leti u kojoj kategoriji."
  },
  {
    title: "Otvorena kategorija",
    content: "Za najširu zainteresiranu zajednicu: fotografi, geodeti, modelari… MTOM (najveća dopuštena masa pri polijetanju) drona je do 25 kg. Visina leta je do 120 metar iznad terena. Let se izvodi na sigurnoj udaljenosti od neuključenih osoba. Dron je uvijek u vidnom polju. Ne izbacuju se stvari iz drona niti se prevozi opasna roba."
  },
  {
    title: "Posebna kategorija",
    content: "Operacije koje izlaze iz okvira otvorene kategorije. Dronovi čiji je MTOM veći od 25 kg. BVLOS operacije – operacije van vidnog polja udaljenog pilota. Operacije iznad 120 m. Operacije u skladu sa standardnim scenarijima. Operacije u skladu s prethodno izrađenim procjenama rizika (PDRA). Operacije u skladu s procjenom rizika za operacije u posebnoj kategoriji (SORA). Operacije u skladu s Jednostavnom svjedodžbom operatora sustava bespilotnog zrakoplova (LUC)."
  },
  {
    title: "Certificirana kategorija",
    content: "Certificirana katagorija. Certificirani zrakoplovi, licencirani piloti, operacije u okruženju u kojem leti i zrakoplovstvo s posadom. Letenje iznad skupina ljudi. Prijevoz opasnih roba. Prijevoz ljudi..."
  },
    {
    title: "",
    content: "Ovisno o klasifikaciji dronova izvode se letačke operacije u potkategorijama Otvorene kategorije. Bitno je uočiti podjelu na dronove koji imaju C oznake i one koji ih nemaju. Popis dronova s C oznakama svakodnevno se ažurira. Dronovi bez C oznake. Do 250 g (Mini, Mini 2, Mini 3 Pro, Mini 4 Pro i sl.) i dalje se leti kao do sada u kategoriji A1. Isto vrijedi i za samogradnju do 250 g. Od 250 g do 25 kg  (Air 2, Mavic 2 Pro, Mavic 3 Pro, Phantomi, Inspire 2, Matrice 300. Matrice 600) mogu letjeti u kategoriji A3. Dronovi s C oznakom. Do 900 g (Air 2S – ako se zatraži C oznaka od proizvođača, Air 3, Mavic 3 Classic i Cine) lete u A1 kategoriji. Od 900 g do 4 kg (Mavic 3 Pro, Matrice M30) lete u A2 kategoriji"
  }
];

function AboutSection() {
  return (
    <section className={styles.aboutSection}>
      <div className={styles.contentWrapper}>
        {contentBlocks.map((block, index) => (
          <ContentBlock key={index} title={block.title} content={block.content} />
        ))}
      </div>
    </section>
  );
}

export default AboutSection;