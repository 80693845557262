import React from 'react';
import PostCard from './PostCard';
import styles from './../../css/NewsPage.module.css';

const posts = [
  {
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/51f84396769c146d3276587d74a97d505f30f4ebe2b457f657d9fe724afcc801?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    title: "Kako odabrati pravo kasko osiguranje za vaš dron?",
    date: "Kolovoz, 2024"
  },
  {
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/1fd4aba3d0d4d9d0040135ebfdf8c00426f906aeea98a6d4b1f39e431397a2e4?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    title: "Sve što trebate znati o registraciji drona",
    date: "Lipanj, 2024"
  },
  {
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/ba3cef62caf3daa82c05b8e91e4ca897e52925bc39606ccc32722bfab5140fd9?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    title: "Osiguranje drona: Što pokriva i zašto je važno?",
    date: "Lipanj, 2024"
  },
  {
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/e285b11175f39d92cd6f63adaa2d05d78980c5711523062b63eb051219d99165?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    title: "Savjeti za sigurnu upotrebu dronova",
    date: "Lipanj, 2024"
  },
  {
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/3cd32f37e1f17a49ed133c8e24a2c0d087713955e722e89223d214996d1efa78?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    title: "Razlike između rekreativnih i komercijalnih letova",
    date: "Svibanj, 2024"
  },
  {
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/42240e455bc8ac5fdf6ecf1875e6b6d0e6e753b6f652effef4176d31ede296e9?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    title: "Kako prijaviti štetu na dronu i što očekivati?",
    date: "Travanj, 2024"
  },
  {
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/4d4cab2248153435f3cf496ff2713292c8e32cb7ce26bd6224e0faf90157cbd1?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    title: "Utjecaj vremenskih uvjeta na letove dronova",
    date: "Travanj, 2024"
  },
  {
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/1cb233ae6e5d86a5c6959f6a08f135fedf843a5facef834c04277ebae2ee2b20?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    title: "Zakonski zahtjevi za upravljanje dronom",
    date: "Veljača, 2024"
  },
  {
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/a81c76784b636682cd016c2c0dd1dcd64bf899a344c72d5de1dedb853eaa767a?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    title: "Prednosti redovitog osposobljavanja",
    date: "Siječanj, 2024"
  }
];

const PostGrid = () => {
  return (
    <div className={styles.postGrid}>
      {posts.map((post, index) => (
        <PostCard key={index} {...post} />
      ))}
    </div>
  );
};

export default PostGrid;