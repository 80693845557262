import React from 'react';
import styles from './../../css/NewsPage.module.css';
import {Link} from "react-router-dom";

const FeaturedPost = () => {
  return (

          <article className={styles.featuredPost}>
              <Link to="/featuredArticle" style={{textDecoration:"none", color:"white"}}>
              <div className={styles.content}>
                  <h2 className={styles.heading}>
                      Najčešći uzroci oštećenja dronova i kako ih izbjeći
                  </h2>
                  <p className={styles.date}>Kolovoz, 2024</p>
              </div>
                   </Link>
          </article>

  );
};

export default FeaturedPost;