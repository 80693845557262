import React from "react";
import styles from './../../css/AboutUs.module.css';
import AboutImage from './../../img/AboutImage.png'
import posrednik from './../../document/Informacije o posredniku u osiguranju.pdf'
import podatci from './../../document/Informacije o obradi osobnih podataka.pdf'

function AboutUs() {
    return (
        <section className={styles.container}>
            <header className={styles.header}>
                <h1 className={styles.companyName}>Semel pro Semper</h1>
                <h2 className={styles.sectionTitle}>O nama</h2>
            </header>
            <div className={styles.contentWrapper}>
                <img
                    loading="lazy"
                    src={AboutImage}
                    className={styles.companyImage}
                    alt="Semel pro Semper company representation"
                />
                <div className={styles.textContent}>
                    <p className={styles.description}>
                        Prvu policu osiguranja od odgovornosti operatora bespilotnog zrakoplova izradili smo 2014.
                        godine i od tada aktivno surađujemo s CCAA i osiguravajućim društvima na razvoju proizvoda za
                        bespilotne zrakoplove. S vremenom smo proširili teritorijalna pokrića za osiguranja od
                        odgovornosti, izradili prve police kasko osiguranja, a zadnja aktivnost je bila na izradi
                        cjenika po kategorijama u skladu s uredbama Europske komisije (EU) 2019/945 i 2019/947. Agencija
                        za zastupanje u osiguranju Semel pro semper d.o.o. osnovana je u 2. mjesecu 2010. godine. Ideja
                        je na jednome mjestu klijentima pružiti ispravne i potpune informacija iz svijeta osiguranja, a
                        osiguranje bespilotnih zrakoplova najbolji je primjer za to.
                    </p>
                    <button className={styles.infoButton} tabIndex="0">
                        <a href={posrednik} target={"_blank"} style={{textDecoration:"none", color:"white"}}>
                             <span className={styles['visually-hidden']}>Click for</span>
                        Infomacije o posredniku u osigiranju
                        </a>
                    </button>
                    <button className={styles.dataButton} tabIndex="0">
                        <a href={podatci} target={"_blank"} style={{textDecoration: "none", color: "white"}}>
                            <span className={styles['visually-hidden']}>Click for</span>
                            Obrada podataka
                        </a>
                    </button>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;