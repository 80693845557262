import React from 'react';
import styles from './../../css/Registration.module.css';
import ServiceStep from './ServiceStep';

function ServiceSteps() {
  const steps = [
    {
      title: "Ugovaranje police",
      description: "Ugovaranje police osiguranja od odgovornosti operatora bespilotnog zrakoplova (drona).",
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/5324acbc44a323900628961c76829fd901398eb18c7961c872e3ad03a421b483?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
      link: { text: "Izračun", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/af8a37944f631dff9dfacf8fc6f54c67a10d9aee51e74a29d8b06860790ec73d?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" }
    },
    {
      title: "Registracija operatora bespilotnog zrakoplova (drona)",
      description: "Registracija operatora na stranicama CCAA. Registarski broj operatora mora se zalijepiti na dron, bez znakova iza minusa.",
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/e4c3e6517d67f2ea2893b9adbbef15b11777af8d138dc4f8bc29248da30521cf?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
      link: { text: "Registracija operatora", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/dc8e48a73e52bf4e1a533f04a33d46783c6a948c33025293d92bd9516c08cf09?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" }
    },
    {
      title: "Polaganje ispita",
      description: "Polaganje ispita za udaljene pilote provodi se neovisno o registraciji operatora (pilot ne mora posjedovati dron).",
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/f6a76f8a2b4bd7ed7bdaf065ae90f2b2d2d2e397c2b4916b695a96da0564c512?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
      link: { text: "Polaganje ispita", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/59a27030ba19c2bc7ebb9529037008fccb5fed3f3559d53c4210f8941bdd421b?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" }
    },
    {
      title: "Prijava leta",
      description: "Registracija pilota i prijava leta na AMC portalu.",
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/14cdb6e8881d2980df9c543ef62757b9822abe4245dc1f30289d43cb05aa7753?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
      link: { text: "Prijava leta", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/40d3fd5831f88a00beaaa66b1a7c52348d0f5a99b9f0ed4ac63e8bdb76fe17e9?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" }
    }
  ];

  return (
    <section className={styles.services}>
      <header className={styles.servicesHeader}>
        <h2 className={styles.servicesTitle}>Koraci u registraciji</h2>
        <div className={styles.serviceDivider} />
        <p className={styles.servicesDescription}>
          Osiguranje drona, registracija operatora, osposobljavanje udaljenog pilota i prijava leta procedura je koju je potrebno odraditi za legalno letenje.
        </p>
      </header>
      <div className={styles.serviceSteps}>
        {steps.map((step, index) => (
          <ServiceStep key={index} step={index + 1} {...step} />
        ))}
      </div>
    </section>
  );
}

export default ServiceSteps;