import React from 'react';
import styles from './../../css/AboutSection.module.css';
import ContentBlock from './ContentBlock';

const contentBlocks = [
  {
    title: "Osiguranje od odgovornosti operatora bespilotnog zrakoplova (drona)",
    content: "Operator bespilotnog zrakoplova odgovoran je upotrebu drona i samim time za štete učinjene trećim osobama. Polica osiguranja od odgovornosti operatora bespilotnog zrakoplova pokriva štete trećim osobama zbog smrti, tjelesne ozljede, narušavanja zdravlja, uništenja ili oštećenja stvari. Minimalna svota osiguranja iznosi 750.000 SDR-a. SDR je međunarodna obračunska jedinica, a vrijednost joj je prosjek dnevne vrijednosti eura, funte, jena, juana i dolara."
  },
  {
    title: "Obveza ugovaranja",
    content: "Obveza ugovaranja police osiguranja od odgovornosti operatora bespilotnog zrakoplova (drona) proizlazi iz Uredbe (EZ) 785/2004 o zahtjevima za zračne prijevoznike i operatore zrakoplova u vezi s osiguranjem. Obveza ugovaranja police osiguranja od odgovornosti ne postoji za bespilotne zrakoplove do 20 kg MTOM-a ako su namijenjeni za rekreaciju i sport (modeli). No, ukoliko je riječ o izvođenju letačkih operacija, komercijalnih (za određenu naknadu) ili nekomercijalnih (snimanje za privatne potrebe), obveza osiguranja postoji bez obzira na najveću dopuštenu masu pri polijetanju. Pojednostavljeno, operator koji leti s dronom koji ima kameru i ona mu služi samo za orijentaciju u prostoru ne mora ugovarati policu osiguranja, ali čim stisne REC on izvodi letačku operaciju i dron osiguranje je obvezno."
  },
  {
    title: "Premija osiguranja i teritorijalno pokriće",
    content: "Premija osiguranja ovisi o teritorijalnom pokriću, namjeni korištenja, masi drona i načinu proizvodnje. Teritorijalno pokriće odnosi se na Hrvatsku, Europu i svijet."
  },
  {
    title: "Namjena korištenja",
    content: "Privatno letenje (sport i hobi). Komercijalno letenje (objava materijala, znanstveni letovi, istraživački letovi, nadzor, zaštita i spašavanje, poljoprivreda, industrija i sl.). MTOM (najveća dopuštena masa pri polijetanju) ili TOW (masa kod uzlijetanja) jer za pojedine dronove nema drugih podataka"
  }
];

function AboutSection() {
  return (
    <section className={styles.aboutSection}>
      <div className={styles.contentWrapper}>
        {contentBlocks.map((block, index) => (
          <ContentBlock key={index} title={block.title} content={block.content} />
        ))}
      </div>
    </section>
  );
}

export default AboutSection;