import React from 'react';
import styles from './../../css/Banner.module.css';
import kasko from "./../../img/Kasko osiguranje.png"

function Banner() {
  return (
    <section className={styles.banner}>
      <img src={kasko} alt="Banner Image" className={styles.bannerImage} />
    </section>
  );
}

export default Banner;