import React from 'react';
import styles from './../../css/HistorySection.module.css';
import HistoryItem from './HistoryItem';

const historyData = [
  {
    year: '2015',
    title: 'Ugovorena prva polica osiguranja s odgovarajućom svotom',
    description: 'Ugovorena prva polica osiguranja s odgovarajućom svotom\',\n' +
        '    description: \'Prije deset godina prvi put sam se susreo s pojmom dron osiguranja, odnosno onime što je potrebno svakom operatoru za izvođenje letačkih operacija, a to je osiguranje od odgovornosti operatora bespilotnog zrakoplova. Izvođenje letačkih operacija tada još nije bilo regulirano od strane države pa su svote osiguranja i pokrića bila neadekvatna. U to vrijeme nitko od osiguratelja nije imao odgovarajuću policu osiguranja, a prva s odgovarajućom svotom ugovorena je 1.7.2015. Nakon toga bilo je realno za očekivati da će osiguratelji dopuniti i korigirati ponudu svojih proizvoda, ali industrija dronova tada, kao uostalom niti sada, nije interesantna veći osiguratelja.',
    subtitle: 'Pravilnik o sustavima bespilotnih zrakoplova',
    subdescription: 'Pravilnik o sustavima bespilotnih zrakoplova stupio je na snagu 2.5.2015. i u njemu je, između ostalog, bilo navedeno da „Operator mora ishoditi policu osiguranja u skladu s propisom kojim se uređuju obvezna osiguranja u prometu.“ Ovo je omogućilo dobru poziciju za korekciju premije i umjesto dotadašnjih 1.592 kn godišnje po dronu, neovisno o namjeni korištenja i masi drona, premija je u suradnji s drugim osigurateljem smanjena na 960 kn. ',
    backgroundColor: '#fff'
  },
  {
    year: '2016',
    title: 'Prva polica kasko osiguranja',
    description: 'Krajem 2016. ugovorena je i prva polica kasko osiguranja za dron DJI S1000, ali kako je ubrzo nakon ugovaranja osiguratelj isplatio 8.000€ radi pada i uništenja drona, odustali su od daljnjih prihvata dronova u osiguranje. Jedan manje na vrlo malom tržištu osiguranja dronova.',
    backgroundColor: '#2f4156' // Keep dark background for this item
  },
  {
    year: '2018',
    title: 'Novi pravilnik o sustavima bespilotnih zrakoplova',
    description: 'U novom Pravilniku o sustavima bespilotnih zrakoplova koji stupa na snagu 15.12.2018. osiguranja se ne spominju, iako su i dalje obvezna zbog Zakona o obveznim osiguranjima u prometu, što dodatno smanjuje interes operatora za ugovaranjem polica osiguranja za dronove.',
    backgroundColor: '#fff'
  },
  {
    year: '2019',
    title: 'Suradnja s novim osiguravateljima',
    description: 'Konačno, najava paketa propisa koji se odnose na upotrebu sustava bespilotnih zrakoplovna odnosno Provedbene Uredbe Komisije (EU) 2019/947 otvara vrata suradnje s novim osigurateljem s kojim dogovaram podjelu premije prema masi drona, tada još masi kod polijetanja (TOW) jer većina dronova nije imala specificiranu najveću dopuštenu masu kod polijetanja (MTOM), namjeni korištenja i teritorijalnom pokriću. Dodatno smo iznimku napravili za tada vrlo popularan i uspješan dron DJI Mavic 2 Pro pa smo granicu premije postavili na 910 g, iako je granica potkategorije A1 u uredbi postavljena na 900 g MTOM. Premija osiguranja za teritorij Hrvatske za vrlo četo korištene dronove DJI Mini 2, Mini 3, Mini 4 Pro iznosi 59,73€. Policu obveznog osiguranja za dron moguće je ugovorili online uz punu podršku kod registracije i polaganja ispita.',
    backgroundColor: '#C8D9E6'
  }
];

function HistorySection() {
  return (
    <section className={styles.historySection}>
      {historyData.map((item, index) => (
        <HistoryItem
          key={index}
          year={item.year}
          title={item.title}
          description={item.description}
          subtitle={item.subtitle}
          subdescription={item.subdescription}
          backgroundColor={item.backgroundColor}
          isHighlighted={index === 1} // Highlight second item
        />
      ))}
    </section>
  );
}

export default HistorySection;
